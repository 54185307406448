<script lang="ts" setup>
// Dependencies - Vendor
import { computed, type PropType, ref, useId, watch } from 'vue';

// Interfaces/Types - Color Identifier
type ColorId = 'error' | 'success' | 'warning';

// Attributes, Emitted Events, Options, Properties & Slots
const props = defineProps({
    color: { default: 'error', required: false, type: String as PropType<ColorId> }
});

// Utilities - Set Color
const setColor1: (id: string) => string = (id: string): string => {
    return id === 'success' ? '#24e2a3' : id === 'warning' ? '#ffd600' : id === 'error' ? '#ff6565' : 'transparent';
};
const setColor2: (id: string) => string = (id: string): string => {
    return id === 'success' ? '#1bc18a' : id === 'warning' ? '#ccb01d' : id === 'error' ? '#e54747' : 'transparent';
};

// Constants
const GRADIENT_ID = useId();

// Reactive Variables & Watchers
const color1 = ref<string>(setColor1(props.color));
const color2 = ref<string>(setColor2(props.color));
const colorId = computed<string>(() => props.color);
watch(colorId, (newValue: string): void => {
    color1.value = setColor1(newValue);
    color1.value = setColor2(newValue);
});
</script>

<template>
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12">
        <rect :fill="color1" fill-opacity="0.25" height="12" rx="6" width="12" />
        <rect :fill="`url(#${GRADIENT_ID})`" height="7.5" rx="3.75" width="7.5" x="2.25" y="2.25" />
        <defs>
            <linearGradient :id="GRADIENT_ID" gradientUnits="userSpaceOnUse" x1="6" x2="6" y1="2.25" y2="9.75">
                <stop :stop-color="color1" />
                <stop offset="1" :stop-color="color2" />
            </linearGradient>
        </defs>
    </svg>
</template>
