<script lang="ts" setup>
// Dependencies - Vendor
import { computed, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// Dependencies - Framework
import { actionIcons, optionIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';
import type { BenchtopConfig, OptionConfig, WorkbenchConfig } from '../WorkbenchConfig';

// Dependencies - Data
import { workbenchConfigData } from '../WorkbenchConfig';

// Dependencies - Component
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import OptionDrawerSplitButton from './OptionDrawerSplitButton.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Global State
const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();

// Constants
const PATH_SEGMENTS = route.path.split('/');
const WORKFLOW_CONFIG: WorkbenchConfig = workbenchConfigData;

// Reactive Variables & Watchers
const benchtopConfig = shallowRef<BenchtopConfig>(WORKFLOW_CONFIG.benchtops[2]);
const labelsAreVisible = computed<boolean>(() => optionDrawerStateId.value === 'floating' || (optionDrawerStateId.value === 'expanded' && optionDrawerWidthId.value === 'wide'));
const optionDrawerPanelId = computed<string>(() => mainStore.optionDrawerPanelId);
const optionDrawerStateId = computed<string>(() => mainStore.optionDrawerStateId);
const optionDrawerMenuWidth = computed<number>(() => mainStore.optionDrawerMenuWidth);
const optionDrawerWidthId = computed<string>(() => mainStore.optionDrawerWidthId);

// Initialisation
mainStore.optionDrawerPanelId = PATH_SEGMENTS.length > 2 ? PATH_SEGMENTS[2] : 'home';

// UI Event Handlers - Navigate To Benchtop
const handleNavigateToBenchtop = (index: number): void => {
    benchtopConfig.value = WORKFLOW_CONFIG.benchtops[index];
    handleNavigateToOption(WORKFLOW_CONFIG.benchtops[index].options[0], 'home');
};

// UI Event Handlers - Navigate To Option
const handleNavigateToOption = (optionConfig: OptionConfig, optionId: string): void => {
    mainStore.optionDrawerPanelId = optionId;
    if (optionDrawerStateId.value === 'floating') {
        mainStore.collapseOptionDrawer();
    }
    router.push({ path: optionConfig.to });
};

// UI Event Handlers - Toggle Option Drawer
const handleToggleOptionDrawer = () => mainStore.toggleOptionDrawer();
</script>

<template>
    <div
        class="flex max-w-[calc(100%-16px)] flex-col border"
        :class="
            optionDrawerStateId === 'collapsed'
                ? 'absolute h-auto border-transparent dark:border-transparent'
                : `h-full bg-surface-light dark:bg-surface-dark ${optionDrawerStateId === 'floating' ? 'absolute border-separator-light dark:border-separator-dark' : 'border-y-transparent border-l-transparent border-r-separator-light dark:border-y-transparent dark:border-l-transparent dark:border-r-separator-dark'}`
        "
        :style="{ width: `${optionDrawerMenuWidth || 72}px` }"
    >
        <!-- Toggle Button -->
        <div class="flex h-[calc(56px-1px)] flex-none flex-col justify-center px-4">
            <SimpleButton class="flex-none gap-x-2" type="option" @click="handleToggleOptionDrawer()">
                <SVGIcon class="h-9 w-9" :svg="actionIcons.toggleSVG" />
                <div v-if="labelsAreVisible" class="truncate pr-2 text-left">{{ $t('workbench.label') }}</div>
            </SimpleButton>
        </div>

        <!-- Divider -->
        <HorizontalDivider v-if="optionDrawerStateId !== 'collapsed'" class="mx-4" />

        <!-- Options Panel -->
        <div v-if="optionDrawerStateId !== 'collapsed'" class="flex flex-1 flex-col overflow-y-scroll overscroll-y-none px-4" :class="optionDrawerStateId === 'floating' ? '' : ''">
            <!-- Benchtop Options -->
            <div class="flex flex-1 flex-col pb-3">
                <template v-for="(optionConfig, optionIndex) in benchtopConfig.options" :key="optionConfig.id">
                    <HorizontalDivider v-if="optionConfig.isNewGroup" class="mt-2" />

                    <OptionDrawerSplitButton
                        v-if="optionIndex === 0"
                        :benchtopConfig="benchtopConfig"
                        :labelsAreVisible="labelsAreVisible"
                        :optionConfig="optionConfig"
                        @navigateToBenchtop="(index) => handleNavigateToBenchtop(index)"
                        @navigateToOption="(optionConfig, optionId) => handleNavigateToOption(optionConfig, optionId)"
                    />

                    <SimpleButton
                        v-else
                        class="relative mt-2 flex-none gap-x-2"
                        :data-selected="optionConfig.id === optionDrawerPanelId"
                        type="option"
                        @click="handleNavigateToOption(optionConfig, optionConfig.id)"
                    >
                        <SVGIcon class="h-9 w-9" :svg="optionIcons[`${optionConfig.id}SVG`]" />
                        <div v-if="labelsAreVisible" class="truncate pr-2 text-left">{{ $t(`${optionConfig.id}.label`) }}</div>
                    </SimpleButton>
                </template>
            </div>
        </div>
    </div>
</template>
