<script lang="ts" setup>
// Dependencies - Vendor
import { computed, onErrorCaptured, onMounted, watch } from 'vue';
import { useNetwork, useResizeObserver } from '@vueuse/core';

// Dependencies - Framework
import { connectToWebSocket } from '@/services';
import { handleError } from '@/globals';
import { t } from '@/locales';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import LoadingMask from '@/components/LoadingMask.vue';

// Global State
const mainStore = useMainStore();

// Constants
const ALERT_EXPIRES_IN = 5000;

// Non-Reactive Variables
let servicesWebSocket: WebSocket | undefined = undefined;

// Reactive Variables & Watchers - Network Connection Status
const { isOnline } = useNetwork(); // Reactive reference to the browsers network information object.
watch(isOnline, (newValue, oldValue): void => {
    // Check if the network connection online status has shifted from false to true (the network connection is now online).
    if (oldValue === false && newValue === true) {
        // The network connection is online; however, this does not mean we have an active internet connection, so attempt to access the Data Positioning Operations service.
        // The Application Monitor (appMonitor/index.ts) monitors all internet requests and will update the internet connection status reactive reference (see below) accordingly.
        // We do not need to wait for the fetch to finish and can ignore any errors.
        fetch('https://operations.datapositioning.app/config').catch(() => {});
        return;
    }
    // The network connection is offline.
    mainStore.internetConnectionIsOnline = false;
});

// Reactive Variables & Watchers - Internet Connection Status
const internetConnectionIsOnline = computed<boolean | undefined>(() => mainStore.internetConnectionIsOnline); // Reactive reference to the internet connection status.
watch(internetConnectionIsOnline, (newValue, oldValue): void => {
    // Check if the internet connection online status was unset (the application is loading) and is now true (the internet connection is online).
    if (oldValue === undefined && newValue) {
        // No need to display an internet connection alert or reconnect to the Services WebSocket.
        return;
    }

    // Remove any active internet connection alert so we can replace it with one showing the new status.
    mainStore.alertConfigs = mainStore.alertConfigs.filter((alert) => alert.categoryId !== 'offline' && alert.categoryId !== 'online');

    // Check if the internet connection online status has shifted from false to true (the internet connection is now online).
    if (oldValue === false && newValue === true) {
        // The internet connection is online. Connect to the Services WebSocket and show the online alert.
        if (!servicesWebSocket || (servicesWebSocket && (servicesWebSocket.readyState === WebSocket.CLOSED || servicesWebSocket.readyState === WebSocket.CLOSING))) {
            servicesWebSocket = connectToWebSocket();
        }
        mainStore.showAlert({ categoryId: 'online', expiresIn: ALERT_EXPIRES_IN, message: t('internetConnection.online.message'), type: 'default.positive' });
        return;
    }

    // Show the offline alert.
    mainStore.showAlert({ categoryId: 'offline', message: t('internetConnection.offline.message'), type: 'default.negative' });
});

// Reactive Variables & Watchers - Workbench
const workbenchIsEUInstance = computed<boolean | undefined>(() => mainStore.workbenchIsEUInstance);
watch(workbenchIsEUInstance, (newValue): void => {
    newValue === undefined ? void 0 : connectToWebSocket();
});
const workbenchIsLoading = computed<boolean>(() => mainStore.workbenchIsLoading);

// Lifecycle Event Handlers
onErrorCaptured((error): boolean => {
    return handleError(error, { locator: 'App 1' });
});
onMounted((): void => {
    useResizeObserver(document.getElementById('app'), (entries) => {
        mainStore.handleWindowWidthChange(entries[0].contentRect.width);
    });
});

// UI Event Handlers - Window/Document - Before Unload - This is triggered when the current window, contained document, and associated resources are about to be unloaded.
window.addEventListener('beforeunload', (event): void => {
    // Check if updates are pending.
    if (mainStore.sessionUpdatesArePending) {
        // Updates are pending. Trigger browser alert allowing user to cancel unload.
        event.preventDefault();
    }
});
</script>

<template>
    <!-- Application Loading Mask -->
    <Transition name="fade">
        <LoadingMask v-if="workbenchIsLoading" typeLabel="Application" />
    </Transition>

    <!-- Top Level Router View -->
    <RouterView />
</template>
