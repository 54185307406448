<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';

// Dependencies - Framework
import { actionIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Attributes, Emitted Events, Options, Properties & Slots
defineEmits(['action']);

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const toolMenuDataActionsEnabled = computed<boolean>(() => mainStore.toolMenuDataActionsEnabled);
</script>

<template>
    <!-- Add Action -->
    <SimpleButton :disabled="!toolMenuDataActionsEnabled" type="option" @click="$emit('action', 'add')">
        <SVGIcon class="h-9 w-9" :svg="actionIcons.addSVG" />
    </SimpleButton>

    <!-- Save Action -->
    <SimpleButton :disabled="!toolMenuDataActionsEnabled" type="option" @click="$emit('action', 'save')">
        <SVGIcon class="h-9 w-9" :svg="actionIcons.saveSVG" />
    </SimpleButton>

    <!-- Cancel Action -->
    <SimpleButton :disabled="!toolMenuDataActionsEnabled" type="option" @click="$emit('action', 'cancel')">
        <SVGIcon class="h-9 w-9" :svg="actionIcons.cancelSVG" />
    </SimpleButton>
</template>
